import { Box } from "@mui/material";
import { createAnimation, cssValue, parseLengthAndUnit } from "./helpers";

const circle = createAnimation(
    "CircleLoader",
    "0% {transform: rotate(0deg)} 50% {transform: rotate(180deg)} 100% {transform: rotate(360deg)}",
    "circle"
);

function CircleLoader({
    loading = true,
    color = "#000000",
    speedMultiplier = 1,
    cssOverride = {},
    size = 50,
    ...additionalProps
}) {
    const wrapper = {
        display: "inherit",
        position: "relative",
        width: cssValue(size),
        height: cssValue(size),
        ...cssOverride,
    };

    const style = (i) => {
        const { value, unit } = parseLengthAndUnit(size);

        const cssProps = {
            position: "absolute",
            height: `${value * (1 - i / 10)}${unit}`,
            width: `${value * (1 - i / 10)}${unit}`,
            borderTop: `1px solid ${color}`,
            borderBottom: "none",
            borderLeft: `1px solid ${color}`,
            borderRight: "none",
            borderRadius: "100%",
            transition: "2s",
            top: `${i * 0.7 * 2.5}%`,
            left: `${i * 0.35 * 2.5}%`,
            animation: `${circle} ${1 / speedMultiplier}s ${(i * 0.2) / speedMultiplier}s infinite linear`,
        };

        return cssProps;
    };

    if (!loading) {
        return null;
    }

    return (
        <Box component="div" display="flex">
            <Box component="span" style={wrapper} {...additionalProps}>
                <Box component="span" style={style(0)} />
                <Box component="span" style={style(1)} />
                <Box component="span" style={style(2)} />
                <Box component="span" style={style(3)} />
                <Box component="span" style={style(4)} />
            </Box>
        </Box>
    );
}

export default CircleLoader;
