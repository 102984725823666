// Depends on the use of env-cmd as part of yarn scripts (yarn build) in package.json to
// bake the REACT_APP_* keys in process.env variable
const config = {
    apiBasePath: process.env.REACT_APP_API_BASE_PATH || "https://wan.dadlan.au",
    refreshTokenPriorSeconds: process.env.REACT_APP_REFRESH_TOKEN_PRIOR_SECS || 300,
    discord: {
        url: process.env.REACT_APP_DISCORD_URL || "",
    },
};

export default config;
