import { createTheme } from "@mui/material";

export const fontEmoji = `"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;

export const fontSerif = `"Besley", "Droid Serif", ${fontEmoji}`;

export const fontSansSerif = `"Lato", "Roboto", ${fontEmoji}`;

export const fontMonospace = `"Roboto Mono", monospace, ${fontEmoji}`;

export const themeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "#002B00",
        },
        secondary: {
            main: "#f50057",
        },
        background: {
            default: "rgba(234,231,220,0.99)",
            paper: "rgba(255,255,255,0.95)",
        },
    },
    typography: {
        fontFamily: fontSansSerif,
        htmlFontSize: 12,
        fontWeightBold: 900,
        h1: {
            fontSize: "3rem",
            fontWeight: 700,
            fontFamily: "",
        },
        h2: {
            fontSize: "2.7rem",
            fontFamily: fontSerif,
            fontWeight: 500,
        },
        h3: {
            fontSize: "2.4rem",
            fontFamily: fontSerif,
        },
        h4: {
            fontFamily: fontSerif,
            fontSize: "2rem",
        },
        h5: {
            fontFamily: fontSerif,
            fontSize: "1.6rem",
        },
        h6: {
            fontFamily: fontSerif,
            fontSize: "1.2rem",
            fontWeight: 400,
        },
        body1: {
            fontFamily: fontSansSerif,
            fontSize: "1rem",
        },
        subtitle1: {
            fontSize: "0.8rem",
        },
        subtitle2: {
            fontSize: "0.6rem",
        },
        body2: {
            fontFamily: fontSansSerif,
            fontSize: "0.8rem",
        },
        button: {
            fontFamily: fontSansSerif,
            fontSize: "1rem",
        },
        caption: {
            fontFamily: fontSansSerif,
            fontSize: "0.8rem",
        },
        overline: {
            fontSize: "0.8rem",
            fontFamily: fontSerif,
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    marginTop: "0px",
                },
            },
        },
    },
    //     MuiAppBar: {
    //         colorInherit: {
    //             backgroundColor: "#689f38",
    //             color: "#fff",
    //         },
    //     },
    //     MuiSwitch: {
    //         root: {
    //             width: 42,
    //             height: 26,
    //             padding: 0,
    //             margin: 8,
    //         },
    //         switchBase: {
    //             padding: 1,
    //             "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
    //                 transform: "translateX(16px)",
    //                 color: "#fff",
    //                 "& + $track": {
    //                     opacity: 1,
    //                     border: "none",
    //                 },
    //             },
    //         },
    //         thumb: {
    //             width: 24,
    //             height: 24,
    //         },
    //         track: {
    //             borderRadius: 13,
    //             border: "1px solid #bdbdbd",
    //             backgroundColor: "#fafafa",
    //             opacity: 1,
    //             transition:
    //                 "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //         },
    //     },
    //     MuiButton: {
    //         root: {
    //             background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    //             border: 0,
    //             borderRadius: 3,
    //             boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    //             color: "white",
    //             height: 48,
    //             padding: "0 30px",
    //         },
    //     },
    // },
    // props: {
    //     MuiAppBar: {
    //         color: "inherit",
    //     },
    //     MuiList: {
    //         dense: true,
    //     },
    //     MuiMenuItem: {
    //         dense: true,
    //     },
    //     MuiTable: {
    //         size: "small",
    //     },
    //     MuiButton: {
    //         size: "small",
    //     },
    //     MuiButtonGroup: {
    //         size: "small",
    //     },
    //     MuiCheckbox: {
    //         size: "small",
    //     },
    //     MuiFab: {
    //         size: "small",
    //     },
    //     MuiFormControl: {
    //         margin: "dense",
    //         size: "small",
    //     },
    //     MuiFormHelperText: {
    //         margin: "dense",
    //     },
    //     MuiIconButton: {
    //         size: "small",
    //     },
    //     MuiInputBase: {
    //         margin: "dense",
    //     },
    //     MuiInputLabel: {
    //         margin: "dense",
    //     },
    //     MuiRadio: {
    //         size: "small",
    //     },
    //     MuiSwitch: {
    //         size: "small",
    //     },
    //     MuiTextField: {
    //         margin: "dense",
    //         size: "small",
    //     },
    //     MuiTooltip: {
    //         arrow: true,
    //     },
    //     MuiButtonBase: {
    //         disableRipple: false,
    //     },
    // },
};

export const DadWANTheme = createTheme(themeOptions);
