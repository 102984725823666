/**
 * Gets a set of API calls used by the Admin module
 * @param {Axios} apiClient
 * @returns Dictionary of methods to call the API
 */
export function getAdminApiCalls(apiClient, get, post, put, del) {
    return {
        userAddRoles: async (userId, role_ids) =>
            post(`sys/users/${userId}/roles/add`, { role_ids: role_ids }),

        userRemoveRoles: async (userId, role_ids) =>
            post(`sys/users/${userId}/roles/remove`, { role_ids: role_ids }),

        sudoEnable: async () => post("profile/sudo/add"),

        sudoDisable: async () => post("profile/sudo/remove"),
    };
}
