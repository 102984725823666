import { createContext, useContext } from "react";

export const AppLocationContext = createContext({
    location: {},
    getLocation: () => {},
    setLocation: (locationPath, params) => {},
});

AppLocationContext.displayName = "AppLocationContext";

const useAppLocation = () => useContext(AppLocationContext);

export default useAppLocation;
