import React, { createContext, useContext, useState } from "react";
import jwtDecode from "jwt-decode";

import { checkError } from "./errors";
import { createApiClient } from "./client";
import { getAdminApiCalls } from "./calls/admin";
import { getResourceApiCalls } from "./calls/resource";
import { getGuestApiCalls } from "./calls/guest";

let apiClient = createApiClient(null, null, {});

const ApiContext = createContext();
ApiContext.displayName = "ApiContext";

const ApiProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);

    const internalApplyTokenAndLoadProfile = async (access_token, expires_at) => {
        apiClient = createApiClient(access_token, checkRefreshToken);
        setAccessToken(access_token);
        // Convert expires_at from seconds to milliseconds
        setExpiresAt(expires_at * 1000);
        await fetchUserProfile();
        setIsLoggedIn(true);
    };

    const checkRefreshToken = async () => {
        try {
            const response = await apiClient.post("/auth/refresh");
            if (response.status === 200) {
                const { access_token, expires_at } = response.data;
                internalApplyTokenAndLoadProfile(access_token, expires_at);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoggedIn(false);
            }
        }
    };

    const fetchUserProfile = async () => {
        try {
            const response = await apiClient.get("/profile");
            setCurrentUser(response.data);
        } catch (error) {
            console.error("Error fetching user profile:", error);
        }
    };

    const login = async ({ username, password, is_public }) => {
        const form_data = new FormData();
        const grant_type = "password";
        const item = { grant_type, username, password, is_public };
        for (const key in item) {
            form_data.append(key, item[key]);
        }

        try {
            const resp = await apiClient.post("/auth/token", form_data);
            const { access_token, expires_at } = resp.data;
            internalApplyTokenAndLoadProfile(access_token, expires_at);
        } catch (err) {
            return await checkError(err);
        }
    };

    const loginWithDiscordToken = async (discord_token) => {
        try {
            const resp = await apiClient.post("/auth/discord", { ...discord_token });
            const { access_token, expires_at } = resp.data;
            internalApplyTokenAndLoadProfile(access_token, expires_at);
        } catch (err) {
            return await checkError(err);
        }
    };

    const getDiscordToken = async (authorization_code) => {
        try {
            const resp = await apiClient.post("/auth/discord/authorization", {
                code: authorization_code,
            });
            return await Promise.resolve(resp.data);
        } catch (err) {
            return await Promise.reject(err);
        }
    };

    const logout = async () => {
        try {
            await apiClient.post("/auth/logout");
            // Clear application state
            setIsLoggedIn(false);
            setAccessToken(null);
            setExpiresAt(0);
            setCurrentUser(null);
            // Clear any timeouts or intervals here, if you have any token refresh logic
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const getTokenData = () => {
        if (!accessToken) {
            return null;
        }

        return jwtDecode(accessToken);
    };

    //
    //  General HTTP Verb methods
    //
    const get = async (url, config = {}) => apiClient.get(url, config);

    const post = async (url, data, config = {}) => apiClient.post(url, data, config);

    const put = async (url, data, config = {}) => apiClient.put(url, data, config);

    const del = async (url, config = {}) => apiClient.delete(url, config);

    //
    //  Admin module calls
    //
    const adminCalls = getAdminApiCalls(apiClient, get, post, put, del);

    //
    //  Resource module call
    //
    const resourceCalls = getResourceApiCalls(apiClient, get, post, put, del);

    ///
    //  Guest module calls
    //
    const guestCalls = getGuestApiCalls(apiClient, get, post, put, del);

    return (
        <ApiContext.Provider
            value={{
                isLoggedIn,
                user: currentUser,
                accessToken,
                expiresAt,
                login,
                logout,
                loginWithDiscordToken,
                getDiscordToken,
                checkRefreshToken,
                getTokenData,
                get,
                post,
                put,
                del,
                ...adminCalls,
                ...resourceCalls,
                ...guestCalls,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

const useApi = () => useContext(ApiContext);

export { ApiProvider, useApi };
