/**
 * Content types for exporting data.
 * @property {string} TEXT - Plain text content type.
 * @property {string} JSON - JSON content type.
 * @property {string} CSV - CSV content type.
 * @property {string} XML - XML content type.
 * @property {string} ZIP - ZIP content type.
 * @property {string} PDF - PDF content type.
 * @property {string} PNG - PNG image content type.
 * @property {string} JPG - JPEG image content type.
 * @property {string} GIF - GIF image content type.
 * @property {string} SVG - SVG image content type.
 * @property {string} HTML - HTML content type.
 * @property {string} OCTET_STREAM - Octet stream content type.
 * @readonly
 */
const CONTENT_TYPES = {
    TEXT: "text/plain;charset=utf-8",
    CSV: "text/csv;charset=utf-8",
    JSON: "application/json;charset=utf-8",
    XML: "application/xml;charset=utf-8",
    ZIP: "application/zip",
    PDF: "application/pdf",
    PNG: "image/png",
    JPG: "image/jpeg",
    GIF: "image/gif",
    SVG: "image/svg+xml",
    HTML: "text/html",
    OCTET_STREAM: "application/octet-stream",
};

export { CONTENT_TYPES };
