import React from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import App from "./App";
import { AppLocationContext } from "./providers/app_location/AppLocationContext";
import AppLocationProvider from "./providers/app_location/AppLocationProvider";
import { ApiProvider } from "./providers/api";
import { DateTime } from "luxon";
import { FileDownloadProvider } from "./providers/export/FileDownloadProvider";
import { GlobalContextProvider } from "./providers/api/global";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <CssBaseline>
            <GlobalContextProvider>
                <ApiProvider>
                    <FileDownloadProvider>
                        <AppLocationContext.Provider value={AppLocationProvider}>
                            <LocalizationProvider
                                adapterLocale={DateTime.now().locale}
                                dateAdapter={AdapterLuxon}
                            >
                                <App />
                            </LocalizationProvider>
                        </AppLocationContext.Provider>
                    </FileDownloadProvider>
                </ApiProvider>
            </GlobalContextProvider>
        </CssBaseline>
    </React.StrictMode>,
);
