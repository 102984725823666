import { Box, Card, CardContent, Stack, ThemeProvider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { DadWANTheme } from "../../app/Theme";
import CircleLoader from "./CircleLoader";

export const AppLoading = () => {
    return (
        <ThemeProvider theme={DadWANTheme}>
            <Grid
                container
                component="main"
                spacing={2}
                justifyContent="center"
                sx={{ height: "100vh", align: "center", backgroundColor: "primary.main" }}
            >
                <Box component="section">
                    <Card sx={{ mt: 10, p: 4, minWidth: 100, backgroundColor: "primary.light" }}>
                        <CardContent>
                            <Stack alignItems="center">
                                <Typography variant="h5" sx={{ color: "rgb(240,202,163)" }}>
                                    Loading application components...
                                </Typography>
                                <Box sx={{ mt: 4 }}>
                                    <CircleLoader size="6rem" color="rgb(240,202,163)" />
                                </Box>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </ThemeProvider>
    );
};
