/**
 * Check the caught error and respond accordingly
 * @param {Error} err The caught error object
 * @returns A promise that standardises the error response for use in data and auth providers
 */
export function checkError(err) {
    if (undefined === err) {
        return Promise.reject({
            statusText: "Unknown, error is blank",
            status: 500,
            payload: {},
        });
    } else if (err.code === "ERR_NETWORK") {
        return Promise.reject({
            status: 500,
            statusText: "API unreachable or under maintenance",
            payload: "ERR_NETWORK",
        });
    } else if (
        undefined === err.response ||
        err.response.status === 502 ||
        err.response.status === 504 ||
        (err.response.status >= 400 && err.response.status !== 422)
    ) {
        return Promise.reject({
            statusText: err.response?.statusText || err.statusText,
            status: err.response?.status || err.status,
            payload: err.response,
        });
    } else if (err.response.status === 422) {
        const error_payload = { errors: {}, response: err.response, request: err.request };
        err.response.data.detail.forEach((e) => {
            error_payload.errors[e.loc] = `${e.message} error_type:${e.type}`;
        });

        return Promise.reject({
            statusText: err.response?.statusText || err.statusText,
            status: err.response?.status || err.status,
            payload: error_payload,
        });
    }

    return Promise.reject({
        statusText: 500,
        status: "Unknown, check payload",
        payload: err,
    });
}
