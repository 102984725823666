import { createContext, useCallback, useContext } from "react";
import { CONTENT_TYPES } from "./constants";

export const FileDownloadContext = createContext(null);
FileDownloadContext.displayName = "FileDownloadContext";

const FileDownloadProvider = ({ children }) => {
    const download = useCallback((filename, data, contentType = CONTENT_TYPES.TEXT) => {
        const blob = new Blob([data], { type: contentType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;

        // Compatibility for iOS in Safari: attempt to simulate a click event
        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(url);
        a.remove();
    }, []);
    return (
        <FileDownloadContext.Provider
            value={{
                download,
            }}
        >
            {children}
        </FileDownloadContext.Provider>
    );
};

const useFileDownloads = () => useContext(FileDownloadContext);

export { FileDownloadProvider, useFileDownloads };
