const AppLocationProvider = {
    location: {},
    getLocation: () => {
        return AppLocationProvider.location;
    },
    setLocation: (locationPath, params) => {
        let pathNames = locationPath
            .split("/")
            .filter((x) => x)
            .slice();
        pathNames.unshift("/");
        AppLocationProvider.location = {
            path: pathNames,
            params: params,
        };

        return AppLocationProvider.location;
    },
};

export default AppLocationProvider;
