import { createContext, useContext, useRef } from "react";

const GlobalContext = createContext({
    getToken: () => {},
    setToken: (token) => {},
    getFirstCheck: () => {},
    setFirstCheck: (value) => {},
});

GlobalContext.displayName = "GlobalContext";

const GlobalContextProvider = ({ children }) => {
    const accessToken = useRef(null);
    const firstCheck = useRef(true);

    const setToken = (token) => {
        accessToken.current = token;
    };

    const setFirstCheck = (value) => {
        firstCheck.current = value;
    };

    const getToken = () => {
        return accessToken.current;
    };

    const getFirstCheck = () => {
        return firstCheck.current;
    };

    return (
        <GlobalContext.Provider
            value={{
                getToken,
                setToken,
                getFirstCheck,
                setFirstCheck,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalContextProvider, useGlobalContext };
