/**
 * Gets a set of API calls used by the Guest module
 * @param {Axios} apiClient
 * @returns Dictionary of methods to call the API
 */
export function getGuestApiCalls(apiClient, get, post, put, del) {
    return {
        register: async ({ display_name, email, password, registration_token }) => {
            const resp = await post("/register", {
                email: email,
                display_name: display_name,
                password: password,
                registration_token: registration_token,
            });
            return resp.data;
        },

        checkCanRegister: async ({ display_name, username, password }) => {
            const resp = await post("/register/validate", {
                email: username,
                display_name: display_name,
                password: password,
            });
            return resp.data;
        },

        resetAccountRequest: async ({ email }) => {
            const resp = await post("/reset/request", {
                email: email,
            });
            return resp.data;
        },

        checkRegisterToken: async ({ token }) => {
            const resp = await post(`/register/validate/${token}`);
            return resp.data;
        },

        checkVerificationToken: async ({ token }) => {
            const resp = await post(`/verify/validate/${token}`);
            return resp.data;
        },

        claimVerificationToken: async ({ token }) => {
            const resp = await post(`/verify`, { token: token });
            return resp.data;
        },

        checkResetAccountToken: async ({ token }) => {
            const resp = await post(`/reset/validate`, { token: token });
            return resp.data;
        },

        resetAccount: async ({ token, password }) => {
            const resp = await post("/reset", {
                token: token,
                password: password,
            });
            return resp.data;
        },
    };
}
