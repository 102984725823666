import queryString from "query-string";

/**
 *  Gets a set of API calls for the Resource module
 * @param {Axios} apiClient
 * @returns Dictionary of methods to call the API
 */
export function getResourceApiCalls(apiClient, get, post, put, del) {
    return {
        getList: async (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort_field: field,
                sort_order: order,
                range_start: page * perPage,
                range_end: page * perPage + perPage,
                filter: JSON.stringify(params.filter),
                history: params.history || false,
            };
            const url = `${resource}?${queryString.stringify(query)}`;

            return get(url);
        },

        getOne: (resource, id) => get(`${resource}/${id}`),

        create: (resource, params, options = undefined) => {
            const url = `${resource}`;
            const payload = { ...params.data };

            return post(url, payload, options);
        },

        update: (resource, params, options = undefined) => {
            const url = `${resource}`;
            const payload = {
                ids: [params.id],
                data: params.data,
            };
            return put(url, payload, options);
        },
    };
}
