import React, { Suspense, useEffect } from "react";
import { AppLoading } from "./components/loading/AppLoading";
import { useApi } from "./providers/api/provider";
import { useGlobalContext } from "./providers/api/global";
import config from "./config";

const AppAuthenticated = React.lazy(() => import("./app/AppAuthenticated"));
const AppUnauthenticated = React.lazy(() => import("./app/AppUnauthenticated"));

function App() {
    const { getFirstCheck, setFirstCheck } = useGlobalContext();
    const { isLoggedIn, checkRefreshToken, accessToken, expiresAt } = useApi();

    useEffect(() => {
        if (getFirstCheck()) {
            checkRefreshToken();
            setFirstCheck(false);
        }
    }, [checkRefreshToken, getFirstCheck, setFirstCheck]);

    // Check accessToken expiry and refresh if necessary
    useEffect(() => {
        if (!accessToken || !expiresAt) {
            return;
        }

        // If the token expires in less than the configured time, don't refresh
        if (expiresAt - Date.now() <= config.refreshTokenPriorSeconds * 1000) {
            return;
        }

        // Refresh however many configured seconds before the token expires
        let expiresIn = expiresAt - (Date.now() + config.refreshTokenPriorSeconds * 1000);
        if (expiresIn < 1000) {
            expiresIn = 1000;
        }
        const timeout = setTimeout(checkRefreshToken, expiresIn);
        return () => clearTimeout(timeout);
    }, [accessToken, expiresAt, checkRefreshToken]);

    return (
        <Suspense fallback={<AppLoading />}>
            {isLoggedIn ? <AppAuthenticated /> : <AppUnauthenticated />}
        </Suspense>
    );
}

export default App;
