import axios from "axios";
import config from "../../config";
import { checkError } from "./errors";

const BASE_API_URL = `${config.apiBasePath}/api/v1`;

const DEFAULT_AXIOS_OPTIONS = {
    withCredentials: true,
};

export function createApiClient(accessToken = null, refreshCallback, options = {}) {
    options = { ...DEFAULT_AXIOS_OPTIONS, ...options };
    const axiosInstance = axios.create({ baseURL: BASE_API_URL, ...options });

    if (accessToken !== null) {
        axiosInstance.interceptors.request.use(
            async (axiosConfig) => {
                if (!accessToken) {
                    const newAccessToken = await refreshCallback();
                    axiosConfig.headers.Authorization = `Bearer ${newAccessToken}`;
                } else {
                    axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
                }
                return axiosConfig;
            },
            (error) => {
                return checkError(error);
            },
        );

        // Intercept 401 responses to attempt token refresh
        axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response.status === 401) {
                    const newAccessToken = await refreshCallback();
                    if (newAccessToken) {
                        error.config.headers["Authorization"] = "Bearer " + newAccessToken;
                        return axios(error.config);
                    }
                }
                return checkError(error);
            },
        );
    } else {
        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                return checkError(error);
            },
        );
    }

    return axiosInstance;
}
